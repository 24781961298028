import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'components/Layout'
import ImageWrap from 'components/ImageWrap'
import PageLink from 'components/PageLink'

export default function ThankYou({ data }) {
  const page = data.wpPage
  const { image2, copy2 } = page.template.thankYou

  return (
    <Layout
      bodyClassName='page-template page-template-page-builder page-builder page page-id-22319 page-child parent-pageid-130 thank-you app-data index-data singular-data page-data page-22319-data page-thank-you-data page-builder-data'
      page={page}
    >
      <section className='multivariate-grid multivariate-grid--horizontal-clip hero'>
        <Image content={image2} />
        <Copy content={copy2} />
      </section>
    </Layout>
  )
}

const Image = ({ content }) => {
  const { desktop, mobile } = content
  return (
    <section className='image'>
      <div className='picture'>
        {desktop && <ImageWrap className='desktop-only' image={desktop} />}
        {mobile && <ImageWrap className='mobile-only' image={mobile} />}
      </div>
    </section>
  )
}

const Copy = ({ content }) => {
  const { title, excerpt, pageLink, pageLinkText } = content
  return (
    <div className='hero__container multivariate-grid__container'>
      <div className='hero__inner-container'>
        <h1 className='hero__title hero__title-tag-h1'>{title}</h1>
        {excerpt && (
          <div className='hero__excerpt'>
            <h4>{excerpt}</h4>
          </div>
        )}
        {pageLink && (
          <div className='hero__cta-list hero__cta-list--1'>
            <PageLink pageLink={pageLink} className='hero__cta'>
              {pageLinkText}
            </PageLink>
          </div>
        )}
      </div>
    </div>
  )
}

export const query = graphql`
  query ($slug: String!) {
    wpPage(slug: { eq: $slug }) {
      title
      ...SeoPage
      ...ContactBlockPage
      template {
        ... on WpTemplate_ThankYou {
          thankYou {
            image2 {
              desktop {
                ...GatsbyImage
              }
              mobile {
                ...GatsbyImage
              }
            }
            copy2 {
              title
              excerpt
              pageLink {
                ...PageLinkPageInner
                ...PageLinkPostInner
              }
              pageLinkText
            }
          }
        }
      }
    }
  }
`
